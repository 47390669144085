import { logout } from '../redux/authSlice';
import { setNavigate } from '../redux/navigateSlice';
import { getStore, getThunkDispatch } from '../redux/store';
import { createMessage, isUndefined } from './common_utils';
import axios from 'axios';


const instance = axios.create({
    //baseURL: 'http://192.168.0.24:5000/'
    //baseURL: 'https://test.be.rezervathor.com'
    baseURL: 'https://be.rezervathor.com'
});


instance.interceptors.request.use(request => {
    const session = getStore().getState()?.auth;
    const token = session.token;
    const expirationDate = typeof session?.expirationDate === 'string' ? Date.parse(session?.expirationDate) : session?.expirationDate;
    
    if (expirationDate !== undefined && expirationDate < new Date() && !isUndefined(token)) {
        getThunkDispatch()(logout());
    } else if (!isUndefined(token) && expirationDate !== undefined && expirationDate > new Date()) {
        request.headers.setAuthorization("Bearer " + token);
    }
    return request;
});

instance.interceptors.response.use(
    response => {
        if (response.data.status === 'error') {
            createMessage(response.data.status, response.data.message);
            getThunkDispatch()(setNavigate("/secured/500"));
        }
        return response;
    },
    error => {
        const { token } = getStore().getState()?.auth;
        if (error.status === 401) {
            createMessage('error', 'Špatné přihlašovací údaje.');
            return Promise.reject(error);
        }

        createMessage("error", "Nastala neočekávaná chyba. Vyzkoušejte prosím za chvíli.");
        if (token) getThunkDispatch()(logout());
    }
  );

export default instance;